@import "styles/fontSizes";

:root {
  --du-color-primary: #128370;
  --du-color-primary-dark: #006d68;
  --du-color-primary-aquamarine: #007672;
  --du-color-primary-darkest: #0c5b4d;
  --du-color-primary-light: #6dd8cd;
  --du-color-primary-semitransparent: #12837033;
  --du-color-primary-pale: #e2f7f5;
  --du-color-black: #000;
  --du-color-black_07: #0007;
  --du-color-black_85: rgba(0, 0, 0, 0.85);
  --du-color-black_45: rgba(0, 0, 0, 0.45);
  --du-color-black_70: rgba(0, 0, 0, 0.7);
  --du-color-black_20: rgba(0, 0, 0, 0.2);
  --du-color-black_6: rgba(0, 0, 0, 0.06);
  --du-color-black-shadow: #0003;
  --du-color-white: #fff;
  --du-color-white-pale: #f0f0f0;
  --du-color-green: #58b947;
  --du-color-green-pale: #eef7d9;
  --du-color-grey: #969696;
  --du-color-grey-light: #e1e1e1;
  --du-color-grey-pale: #f8f8f8;
  --du-color-grey-F8: rgba(248, 248, 248, 0.5);
  --du-color-grey-E3: #e3e3e3;
  --du-color-grey-background: #8798ad;
  --du-color-red: #e4001c;
  --du-color-red-dark: #aa0014;
  --du-color-red-banner-bg: #fff0f2;
  --du-color-red-light: #e4001c33;
  --du-color-orange: #ff9e19;
  --du-color-orange-light: #fffbcc;
  --du-color-orange-dark: #f09211;
  --du-color-orange-semitransparent: #ff9e1933;
  --du-color-icon: #000000;
  --du-color-hover: #007672;
  --du-color-icon-disabled: #969696;
  --du-color-secondary-58b947: #58b947;
  --du-color-grey-700: #344054;
  --du-color-grey-900: #101828;
  --du-color-grey-1000: #dbdbdb;
  --du-color-secondary-blue: #00a9e0;
  --du-color-secondary-blue_20: rgba(0, 169, 224, 0.2);
  --du-color-cyan-blue: #004c97;
  --du-color-orange-med-light: #d9680d;
  --du-color-orange-med-dark: #f8b57e;
  --du-color-pale-blue: #eaf4fb;
  --du-color-light-blue: #88dcf9;
  --du-color-light-pink: #ffc0cb;
  --du-color-lightest-pink: #ffede4;
  --du--color-light-red: #ff4d4f;
  --du-color-black-opacity_70: #000000b3;
  --du-color-white-opacity_80: rgba(255, 255, 255, 0.8);
}

/* Hide the up and down arrows in WebKit-based browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the up and down arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 8px;
  padding: 0 3px;
  margin-right: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 5px;
}

.table> :not(caption)>*>* {
  background-color: transparent !important;
}

body {
  margin: 0;
  font-family: "NotoSans";
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.margin-left-auto {
  margin-left: auto;
}

.vertical-center {
  display: flex;
  height: 100%;
  align-items: center;
}

.margin-right-10 {
  margin-right: 10px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.max-width-100 {
  max-width: 100%;
}

.no-page-break {
  page-break-inside: avoid !important;
}

.app-max-width {
  max-width: 1440px;
}

/* overriding react flow css */
.react-flow__attribution {
  display: none !important;
}

.canvas {
  border: none !important;
}

.position-relative {
  position: relative;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.bg-white {
  background-color: var(--du-color-white);
}

.bg-f8 {
  background-color: var(--du-color-grey-pale);
}

.p-16 {
  padding: 16px;
}

.p-32 {
  padding: 32px;
}

.mb-16 {
  margin-bottom: 16px;
}

.color-black {
  color: var(--du-color-black) !important;
}

.flex-1 {
  flex: 1;
}

.flip-icon {
  transform: scaleX(-1);
}

.overflow-x-auto {
  overflow-x: auto;
}